<template>
  <form @submit.prevent="save" class="w-m-content" v-if="!success && !loading">
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin" style="color: #293e52">Configure e personalize a listagem</p>
      <div class="m-t-xs font-13 text-grey-7" style="color: #748396; padding-right: 20px; line-height: 1">
        Esta configuração se aplica somente ao seu usuário. Para uma configuração global, utilize a aba de configurações
        do sistema como administrador.
      </div>
    </div>
    <div class="w-m-body e-input-modern">
      <div style="min-height: 400px" class="font-11">
        Campos da listagem:
        <br><br>
        <e-row v-for="opt in lista" :key="opt.name" class="m-b-sm">
          <erp-checkbox class="flex items-center" v-model="opt.active">
            <label class="no-select font-bold">
              <span class="m-l-xs">{{opt.label}}</span>
            </label>
          </erp-checkbox>
        </e-row>
      </div>
    </div>
    <div class="w-m-footer text-right">
      <u-btn type="submit" label="Salvar configurações" no-caps
             :color="'primary'"
             class="b-radius-3px" />
    </div>
  </form>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 class="m-t">Lista configurada com sucesso!</h5>
    <div class="m-t text-blue-grey-7">
      ...
    </div>
    <div class="text-center m-t">
      <timer-close-window ref="timer" :timer="3" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
import ERow from '../../layout/components/Row'
// import {ErpInput} from 'uloc-vue-plugin-erp'
// import ECol from '../../layout/components/Col'
//import {/*UCheckbox, */URadio} from 'uloc-vue'
import {ErpCheckbox} from 'uloc-vue-plugin-erp'
import WindowSuccess from '../../layout/window/Success'
import TimerCloseWindow from '../../layout/window/TimerClose'
import WindowLoading from '../../layout/window/Loading'


export default {
  name: 'UsuarioWindowModern',
  components: {WindowLoading, TimerCloseWindow, WindowSuccess, /*ECol, */ERow, /*ErpInput, *//*UCheckbox, *//*URadio, */ErpCheckbox},
  props: ['options'],
  data () {
    return {
      loading: false,
      success: false,
      status: '',
      lista: this.options
    }
  },
  mounted () {
  },
  computed: {},
  methods: {
    save () {
      this.$uloc.window.emit(this.$root.wid, 'updated', this.lista)
      this.success = true
      this.$nextTick(() => {
        this.$refs.timer.start()
      })
    }
  }
}
</script>
